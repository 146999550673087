<template>
  <div class="pdf-watermark">
    <div class="pdf-watermark__left">
      <vh-form
        class="pdf-watermark-form"
        :class="[{ disabled: !formPdf.enable }]"
        :model="formPdf"
        ref="ruleForm"
        label-width="100px"
      >
        <vh-form-item label="下载文档水印">
          <vh-switch
            v-model="formPdf.enable"
            active-color="#ff4949"
            inactive-color="#ccc"
            :active-value="1"
            :inactive-value="0"
            @change="switchChange"
            :active-text="switchText"
          ></vh-switch>
        </vh-form-item>
        <vh-form-item label="水印文字">
          <vh-input
            v-model="formPdf.text"
            placeholder="版权所有，盗版必究"
            maxlength="20"
            show-word-limit
            @change="editWaterInfo"
          ></vh-input>
        </vh-form-item>
        <vh-form-item label="文字大小">
          <vh-select
            v-model="formPdf.font_size"
            placeholder="请选择"
            :disabled="!formPdf.enable"
            style="margin-bottom: 10px"
          >
            <vh-option
              v-for="item in fontSizeOptions"
              :key="item"
              :label="item"
              :value="item"
            ></vh-option>
          </vh-select>
        </vh-form-item>
        <vh-form-item label="文字颜色">
          <color-set
            ref="pageThemeColorsDoc"
            :themeKeys="defaultColors"
            :openSelect="true"
            @color="colorChange"
            :colorDefault="formPdf.font_color"
          ></color-set>
        </vh-form-item>
        <vh-form-item label="不透明度">
          <div class="pdf-alpha">
            <vh-slider v-model="formPdf.opacity" :disabled="!formPdf.enable"></vh-slider>
            <span class="pdf-alpha__num">{{ formPdf.opacity }}%</span>
          </div>
        </vh-form-item>
        <vh-form-item label="多行水印">
          <vh-radio v-model="formPdf.show_line" :label="0">单行</vh-radio>
          <vh-radio v-model="formPdf.show_line" :label="1">多行</vh-radio>
        </vh-form-item>
        <vh-form-item label="旋转水印">
          <vh-radio v-model="formPdf.rotating" :label="-45">倾斜</vh-radio>
          <vh-radio v-model="formPdf.rotating" :label="0">水平</vh-radio>
        </vh-form-item>

        <vh-form-item>
          <vh-button
            :disabled="!formPdf.enable"
            type="primary"
            round
            style="width: 160px"
            @click="handleSubmit"
          >
            保存
          </vh-button>
        </vh-form-item>
      </vh-form>
    </div>
    <div class="pdf-watermark__right">
      <div class="pdf-preview">
        <div class="pdf-preview__hd">预览</div>
        <div class="pdf-preview__bd" :class="{ active: !formPdf.enable }">
          <WatermarkPreview
            v-if="!!formPdf.enable"
            :width="300"
            :height="400"
            :text="formPdf.text"
            :opacity="formPdf.opacity"
            :color="formPdf.font_color"
            :fontSize="formPdf.font_size"
            :showLine="formPdf.show_line"
            :rotate="formPdf.rotating"
          ></WatermarkPreview>
        </div>
        <div class="give-white" v-if="!formPdf.enable"></div>
      </div>
    </div>
  </div>
</template>
<script>
  import WatermarkPreview from '@/components/WartermarkPreview';
  import ColorSet from '@/components/ColorSelect';
  import to from 'await-to-js';
  import { isEmpty } from '@/utils/utils';

  export default {
    components: {
      WatermarkPreview,
      ColorSet
    },
    data() {
      return {
        formPdf: {
          enable: 0, //开关
          text: '版权所有，盗版必究', // 水印文本
          font_color: '#BFBFBF', // 文字颜色
          font_size: 14, // 文字大小，默认14
          opacity: 10, // 不透明度
          show_line: 0, // 0:单行， 1：多行
          rotating: -45 // 倾斜角度，  倾斜：-45 水平：0
        },
        fontSizeOptions: [12, 16, 20, 24, 28, 36, 48, 56, 64, 72, 80, 90, 96, 105, 120, 144],
        defaultColors: ['BFBFBF', '1A1A1A', 'FB3A32', 'FFB201', '16C973', '3562FA']
      };
    },
    computed: {
      switchText() {
        /* prettier-ignore */
        return `${this.formPdf.enable ? '已开启' : '开启后'}，导出PDF格式数据带有水印，版权声明防泄漏`;
      },
      // true：活动下，false：账号下
      isInWebinar() {
        return this.$route.path.indexOf('/live/safeScreenSet') > -1;
      }
    },
    methods: {
      colorChange(val) {
        this.formPdf.font_color = val;
      },
      // 开关状态
      switchChange(val) {
        if (!val) {
          this.saveDownloadDocWatermark();
        }
      },
      // 保存设置
      async saveDownloadDocWatermark() {
        let params = {
          enable: this.formPdf.enable
        };
        if (this.formPdf.enable) {
          params = {
            ...this.formPdf
          };
        }
        if (this.isInWebinar) {
          params.webinar_id = this.$route.params.str;
        }
        const [err, res] = await to(this.$fetch('setDownloadDocWatermark', params));
        if (err || !res || res.code != 200) {
          this.$message.error(err.msg || res?.msg || '请求失败');
          this.formPdf.enable = 1; //还原
          return;
        }
        if (this.formPdf.enable) {
          this.$vhMessage.success('保存成功');
        } else {
          this.$vhMessage.success('下载文档文档水印设置关闭成功');
        }
      },
      // 获取下载文档水印设置
      async getDownloadDocWatermark() {
        let params = {};
        if (this.isInWebinar) {
          params.webinar_id = this.$route.params.str;
        }

        const [err, res] = await to(this.$fetch('getDownloadDocWatermark', params));
        if (err || !res || res.code != 200) {
          this.$message.error(err.msg || res?.msg || '请求失败');
          return;
        }
        // console.log('res:', res);
        if (!isEmpty(res.data)) {
          this.formPdf = res.data;
        }
      },
      // 保存
      handleSubmit() {
        this.saveDownloadDocWatermark();
      },
      //表单被修改
      editWaterInfo() {
        if (!this.formPdf.text) {
          this.formPdf.text = '版权所有，盗版必究';
        }
      }
    },
    created() {
      this.getDownloadDocWatermark();
    }
  };
</script>
<style lang="less" scoped>
  .pdf-watermark {
    padding: 40px 24px;
    display: flex;

    &__left {
      width: 560px;

      .pdf-watermark-form {
        position: relative;
      }
      .pdf-watermark-form.disabled::after {
        content: ' ';
        position: absolute;
        top: 50px;
        left: 0;
        right: 0;
        bottom: 50px;
        background-color: hsla(0, 0%, 100%, 0.5);
        z-index: 9;
      }

      .pdf-alpha {
        width: 95%;
        display: flex;
        flex-direction: row;
        align-items: center;
        .vh-slider {
          flex: 1;
        }
        &__num {
          width: 70px;
          text-align: center;
        }
      }
    }

    &__right {
      width: 300px;
      margin: 0 auto;
      text-align: center;

      .pdf-preview {
        display: flex;
        flex-direction: column;
        position: relative;
        &__hd {
          text-align: left;
          height: 32px;
          line-height: 32px;
          font-size: 14px;
          color: #262626;
        }

        &__bd {
          flex: 1;
          &.active {
            border: 1px solid #ccc;
            width: 300px;
            height: 400px;
            min-height: 400px;
            box-sizing: border-box;
          }
        }
        .give-white {
          z-index: 9;
          position: absolute;
          top: 27px;
          left: -10px;
          right: 0;
          bottom: 0;
          width: 320px;
          height: 410px;
          background-color: rgba(255, 255, 255, 0.5);
        }
      }
    }
  }
</style>

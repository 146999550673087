<template>
  <div class="prize-card">
    <pageTitle pageTitle="防录屏设置">
      <div class="title_text" v-if="isInWebinar">
        <p class="switch__box">
          <vh-switch
            v-model="playerOpen"
            active-color="#FB3A32"
            inactive-color="#CECECE"
            @change="closePlayerOpen"
            :active-text="reservationDesc"
          ></vh-switch>
          <span v-if="!isEmbed" class="tip-text" @click="toSettingDetail">
            查看账号下防录屏设置
          </span>
        </p>
      </div>
    </pageTitle>
    <div class="player-set" :class="activeName" style="min-height: 741px">
      <vh-tabs v-model="activeName" @tab-click="handleClick">
        <vh-tab-pane label="防录屏跑马灯" name="first">
          <div class="give-item">
            <div class="give-prize">
              <vh-form :model="formHorse" ref="ruleForm" :rules="rules" label-width="100px">
                <vh-form-item label="跑马灯">
                  <p class="switch__box">
                    <vh-switch
                      v-model="scrolling_open"
                      active-color="#ff4949"
                      inactive-color="#ccc"
                      @change="closeHorseInfo"
                      :active-text="horseLampText"
                    ></vh-switch>
                  </p>
                </vh-form-item>
                <vh-form-item label="显示方式">
                  <vh-radio
                    v-model="formHorse.scroll_type"
                    :label="1"
                    :disabled="!scrolling_open"
                    @change="editHorseInfo"
                  >
                    滚动
                  </vh-radio>
                  <vh-radio
                    v-model="formHorse.scroll_type"
                    :label="2"
                    :disabled="!scrolling_open"
                    @change="editHorseInfo"
                  >
                    闪烁
                  </vh-radio>
                </vh-form-item>
                <vh-form-item label="文本类型">
                  <vh-radio
                    v-model="formHorse.text_type"
                    :label="1"
                    :disabled="!scrolling_open"
                    @change="editHorseInfo"
                  >
                    固定文本
                  </vh-radio>
                  <vh-radio
                    v-model="formHorse.text_type"
                    :label="2"
                    :disabled="!scrolling_open"
                    @change="editHorseInfo"
                  >
                    观看者ID和昵称
                  </vh-radio>
                </vh-form-item>
                <vh-form-item label="固定文本">
                  <vh-input
                    v-model="formHorse.text"
                    class="textType"
                    placeholder="版权所有，盗版必究"
                    :disabled="!scrolling_open"
                    autocomplete="off"
                    :maxlength="20"
                    v-clearEmoij
                    show-word-limit
                    @change="editHorseInfo"
                  ></vh-input>
                </vh-form-item>
                <vh-form-item label="文字大小">
                  <vh-select
                    v-model="formHorse.size"
                    placeholder="请选择"
                    :disabled="!scrolling_open"
                    style="margin-bottom: 10px"
                    @change="editHorseInfo"
                  >
                    <vh-option
                      v-for="item in fontList"
                      :key="item.value"
                      :label="item.value"
                      :value="item.value"
                    ></vh-option>
                  </vh-select>
                </vh-form-item>
                <vh-form-item label="文字颜色" prop="color">
                  <color-set
                    ref="pageThemeColors"
                    :themeKeys="pageThemeColors"
                    :openSelect="true"
                    @color="pageStyleHandle"
                    :colorDefault="formHorse.color"
                  ></color-set>
                </vh-form-item>
                <vh-form-item label="不透明度">
                  <vh-slider
                    v-model="formHorse.alpha"
                    :disabled="!scrolling_open"
                    style="width: 315px"
                    @change="editHorseInfo"
                  ></vh-slider>
                  <span class="isNum">{{ formHorse.alpha }}%</span>
                </vh-form-item>
                <vh-form-item label="移动速度" v-if="formHorse.scroll_type == 1">
                  <vh-radio
                    v-model="formHorse.speed"
                    :label="10000"
                    :disabled="!scrolling_open"
                    @change="editHorseInfo"
                  >
                    慢
                  </vh-radio>
                  <vh-radio
                    v-model="formHorse.speed"
                    :label="6000"
                    :disabled="!scrolling_open"
                    @change="editHorseInfo"
                  >
                    中
                  </vh-radio>
                  <vh-radio
                    v-model="formHorse.speed"
                    :label="3000"
                    :disabled="!scrolling_open"
                    @change="editHorseInfo"
                  >
                    快
                  </vh-radio>
                </vh-form-item>
                <vh-form-item label="显示位置">
                  <vh-radio
                    v-model="formHorse.position"
                    :label="1"
                    :disabled="!scrolling_open"
                    @change="editHorseInfo"
                  >
                    随机
                  </vh-radio>
                  <vh-radio
                    v-model="formHorse.position"
                    :label="2"
                    :disabled="!scrolling_open"
                    @change="editHorseInfo"
                  >
                    上
                  </vh-radio>
                  <vh-radio
                    v-model="formHorse.position"
                    :label="3"
                    :disabled="!scrolling_open"
                    @change="editHorseInfo"
                  >
                    中
                  </vh-radio>
                  <vh-radio
                    v-model="formHorse.position"
                    :label="4"
                    :disabled="!scrolling_open"
                    @change="editHorseInfo"
                  >
                    下
                  </vh-radio>
                </vh-form-item>
                <vh-form-item label="间隔时间" prop="interval" v-if="formHorse.scroll_type == 1">
                  <vh-input
                    v-model="formHorse.interval"
                    :disabled="!scrolling_open"
                    maxlength="3"
                    @blur="blurChange"
                    oninput="this.value=this.value.replace(/[^0-9]/g, '')"
                    placeholder="默认20，支持输入范围1-300"
                  >
                    <i slot="suffix">秒</i>
                  </vh-input>
                </vh-form-item>
                <vh-form-item>
                  <vh-button
                    type="primary"
                    round
                    class="common-save length152"
                    v-preventReClick
                    :disabled="!scrolling_open"
                    @click="preFormHorse"
                  >
                    保存
                  </vh-button>
                </vh-form-item>
              </vh-form>
            </div>
            <div
              class="give-white"
              v-show="!(scrolling_open && playerOpen)"
              :class="playerOpen ? 'webinarTop' : 'userTop'"
            ></div>
          </div>
        </vh-tab-pane>
        <vh-tab-pane label="水印设置" name="second">
          <div class="give-item waterSet">
            <div class="give-prize">
              <vh-form :model="formWatermark" ref="ruleForm" label-width="100px">
                <vh-form-item label="播放器水印">
                  <p class="switch__box">
                    <vh-switch
                      v-model="watermark_open"
                      active-color="#ff4949"
                      inactive-color="#ccc"
                      @change="openWaterMarkInfo"
                      :active-text="waterMarkText"
                    ></vh-switch>
                  </p>
                </vh-form-item>
                <vh-form-item label="水印图片" required>
                  <upload
                    v-model="formWatermark.img_url"
                    :domain_url="domain_url"
                    :saveData="{
                      path: 'interacts/watermark-imgs',
                      type: 'image'
                    }"
                    :heightImg="130"
                    :widthImg="231"
                    :on-success="uploadAdvSuccess"
                    :on-progress="uploadProcess"
                    :on-error="uploadError"
                    :on-preview="uploadPreview"
                    :before-upload="beforeUploadHnadler"
                    :disabled="!watermark_open"
                    @delete="deleteImg"
                  >
                    <div slot="tip">
                      <p>建议尺寸：240*80px，小于2M</p>
                      <p>支持jpg、gif、png、bmp</p>
                    </div>
                  </upload>
                </vh-form-item>
                <vh-form-item label="水印大小">
                  <vh-radio-group @change="changeImgSize" v-model="formWatermark.img_size">
                    <vh-radio
                      v-for="(item, index) in imgSize"
                      :key="index"
                      :label="item.value"
                      :disabled="!watermark_open"
                    >
                      {{ item.label }}
                    </vh-radio>
                  </vh-radio-group>
                </vh-form-item>
                <vh-form-item label="水印位置">
                  <vh-radio
                    v-model="formWatermark.img_position"
                    :label="1"
                    :disabled="!watermark_open"
                  >
                    左上角
                  </vh-radio>
                  <vh-radio
                    v-model="formWatermark.img_position"
                    :label="2"
                    :disabled="!watermark_open"
                  >
                    右上角
                  </vh-radio>
                  <vh-radio
                    v-model="formWatermark.img_position"
                    :label="4"
                    :disabled="!watermark_open"
                  >
                    左下角
                  </vh-radio>
                  <vh-radio
                    v-model="formWatermark.img_position"
                    :label="3"
                    :disabled="!watermark_open"
                  >
                    右下角
                  </vh-radio>
                </vh-form-item>
                <vh-form-item label="不透明度">
                  <vh-slider
                    v-model="formWatermark.img_alpha"
                    style="width: 315px"
                    :disabled="!watermark_open"
                  ></vh-slider>
                  <span class="isNum">{{ formWatermark.img_alpha }}%</span>
                </vh-form-item>

                <div class="block"></div>
                <vh-form-item label="文档水印">
                  <p class="switch__box">
                    <vh-switch
                      v-model="docMark_open"
                      active-color="#ff4949"
                      inactive-color="#ccc"
                      @change="closeWaterDocInfo"
                      :active-text="docMarkText"
                    ></vh-switch>
                  </p>
                </vh-form-item>
                <vh-form-item label="文本类型" required>
                  <vh-checkbox
                    v-model="docMarkOption.doc_watermark_type.text"
                    :true-label="1"
                    :false-label="0"
                    :disabled="!docMark_open"
                    @change="editDocWaterInfo"
                  >
                    固定文本
                  </vh-checkbox>
                  <vh-checkbox
                    v-model="docMarkOption.doc_watermark_type.user_id"
                    :true-label="1"
                    :false-label="0"
                    :disabled="!docMark_open"
                    @change="editDocWaterInfo"
                  >
                    观看者ID
                  </vh-checkbox>
                  <vh-checkbox
                    v-model="docMarkOption.doc_watermark_type.nick_name"
                    :true-label="1"
                    :false-label="0"
                    :disabled="!docMark_open"
                    @change="editDocWaterInfo"
                  >
                    观看者昵称
                  </vh-checkbox>
                </vh-form-item>
                <vh-form-item label="固定文本">
                  <vh-input
                    v-model="docMarkOption.doc_watermark_type.text_value"
                    class="textType"
                    placeholder="版权所有，盗版必究"
                    :disabled="!docMark_open || !docMarkOption.doc_watermark_type.text"
                    autocomplete="off"
                    :maxlength="20"
                    v-clearEmoij
                    show-word-limit
                    @change="editDocWaterInfo"
                  ></vh-input>
                </vh-form-item>
                <vh-form-item label="文字大小">
                  <vh-select
                    v-model="docMarkOption.size"
                    placeholder="请选择"
                    :disabled="!docMark_open"
                    style="margin-bottom: 10px"
                    @change="editDocWaterInfo"
                  >
                    <vh-option
                      v-for="item in fontList"
                      :key="item.value"
                      :label="item.value"
                      :value="item.value"
                    ></vh-option>
                  </vh-select>
                </vh-form-item>
                <vh-form-item label="文字颜色" prop="color">
                  <color-set
                    ref="pageThemeColorsDoc"
                    :themeKeys="pageThemeColors"
                    :openSelect="true"
                    @color="pageStyleHandleDoc"
                    :colorDefault="docMarkOption.color"
                  ></color-set>
                </vh-form-item>
                <vh-form-item label="不透明度">
                  <vh-slider
                    v-model="docMarkOption.alpha"
                    :disabled="!docMark_open"
                    style="width: 315px"
                    @change="editDocWaterInfo"
                  ></vh-slider>
                  <span class="isNum">{{ docMarkOption.alpha }}%</span>
                </vh-form-item>
                <vh-form-item>
                  <vh-button
                    type="primary length152"
                    v-preventReClick
                    round
                    class="common-save"
                    :disabled="!watermark_open && !docMark_open"
                    @click="preWatermark(1)"
                  >
                    保存
                  </vh-button>
                </vh-form-item>
              </vh-form>
            </div>
            <div
              class="give-white"
              v-show="!(watermark_open && playerOpen)"
              :class="playerOpen ? 'webinarTop' : 'userTop'"
            ></div>
            <div
              class="give-white-doc"
              v-show="!(docMark_open && playerOpen)"
              :class="playerOpen ? 'webinarTop' : 'userTop'"
            ></div>
          </div>
        </vh-tab-pane>
        <vh-tab-pane name="third" v-if="showThirdTab">
          <span slot="label">
            下载文档水印设置
            <vh-tooltip
              class="item"
              effect="dark"
              content="设置后导出PDF格式的数据带有水印。仅限于「报名表单」数据导出"
              placement="right"
            >
              <i class="iconfont-v3 saasicon_help_m" style="color: #999999"></i>
            </vh-tooltip>
          </span>
          <div class="PdfWatermark">
            <div class="give-white" v-show="!playerOpen"></div>
            <PdfWatermark></PdfWatermark>
          </div>
        </vh-tab-pane>
      </vh-tabs>
      <div class="show-purple" v-show="activeName !== 'third'">
        <div class="video-wrap">
          <div
            id="videoDom"
            :class="{
              player_no_bulletChat: !formOther.bulletChat,
              player_no_doubleSpeed: !formOther.doubleSpeed,
              player_no_progress: !formOther.progress,
              player_no_autoplay: !formOther.autoplay
            }"
          ></div>
          <div
            class="waterMark"
            :class="`position_${formWatermark.img_position} ${imgSizeChose}`"
            v-if="watermark_open"
          >
            <img
              :src="domain_url || audioImg"
              alt=""
              :style="{ opacity: formWatermark.img_alpha + '%' }"
            />
          </div>
        </div>
        <p class="show-purple-info">
          <span>提示</span>
          <span>1.移动端全屏播放时，跑马灯会失效；</span>
          <span>2.安卓手机浏览器劫持可能导致跑马灯失效；</span>
          <span>
            3.因浏览器自身策略，开启自动播放后可能会出现视频自动静音播放的情况，此时观看页会出现「开启音量并调节声音大小」的提示。微信和企微环境内不支持自动播放。
          </span>
          <span>4.分组直播不支持使用跑马灯、水印及弹幕，默认关闭跑马灯、水印及弹幕功能。</span>
          <span>5.竖屏直播的移动观看端不支持展示跑马灯、水印及弹幕，pc观看端不受影响</span>
        </p>
      </div>
      <div class="docMark" v-if="activeName == 'second'">
        <img src="../../../common/images/playerTitle.png" />
        <div class="preview">
          <div class="mark" v-if="docMark_open">
            <div
              v-for="i of 100"
              :key="'mark' + i"
              :style="{
                color: docMarkOption.color,
                fontSize: docMarkOption.size + 'px',
                height: docMarkOption.size * 3 + 'px',
                marginTop: docMarkOption.size + 'px',
                opacity: docMarkOption.alpha / 100
              }"
            >
              <span v-for="i of 20" :key="'mark-' + i">
                {{ docMarkOption.docMarkTxt.substring(0, 20) }}
              </span>
            </div>
          </div>
        </div>
        <div class="alert">
          <span>提示：</span>
          <span>
            1.设置了文档水印后，文字内容将以水印的形式出现在文档区域中，目前支持PC端、移动wap端；
          </span>
          <span>2.文档水印最多显示20个字，超出的水印内容将不会显示；</span>
          <span>
            3.非无延迟直播选择【合并模式】后，未上麦的观众端无法显示水印。
            <a
              v-if="isInWebinar"
              href="javascript:void(0);"
              class="safe__link"
              @click="toLivingSetPage"
            >
              去设置
            </a>
          </span>
        </div>
      </div>
      <div class="cut-line" v-if="activeName == 'second'"></div>
    </div>
    <begin-play
      :webinarId="$route.params.str"
      v-if="$route.query.type != 5 && webinarState != 4 && isInWebinar"
    ></begin-play>

    <vh-dialog title="提示" :before-close="closeDialog" :visible.sync="dialogVisible" width="400px">
      <div class="delay-content">
        当前直播活动为分组直播，暂不支持设置跑马灯、水印、弹幕功能。以上功能仅生效于回放状态。
      </div>
      <span slot="footer" class="dialog-footer">
        <vh-button size="medium" type="primary" round @click="closeDialog">我知道了</vh-button>
      </span>
    </vh-dialog>
  </div>
</template>

<script>
  import PageTitle from '@/components/PageTitle';
  import upload from '@/components/Upload/main';
  import ColorSet from '@/components/ColorSelect';
  import { sessionOrLocal, isEmbed, jump } from '@/utils/utils';
  import beginPlay from '@/components/beginBtn';
  import { paas_record_id_propagandaVideo } from '@/init-global/businessData';
  import controle from './js/control';
  import PdfWatermark from './components/PdfWatermark.vue';

  export default {
    name: 'safeScreenSet',
    mixins: [controle],
    data() {
      const intervalValidate = (rule, value, callback) => {
        if (!value) {
          callback(new Error('间隔时间不能为空'));
        } else {
          if (value < 1) {
            callback(new Error('间隔时间需大于0'));
          } else {
            callback();
          }
        }
      };
      this.$Vhallplayer = null;
      return {
        isEmbed: isEmbed(),
        showDelay: false,
        showGroupLive: false,
        webinarState: JSON.parse(sessionOrLocal.get('webinarState')),
        perssionWebInfo: JSON.parse(sessionOrLocal.get('SAAS_VS_PES', 'localStorage')),
        activeName: 'first',
        playerOpen: true, // 活动下状态，总开关
        scrolling_open: false,
        watermark_open: false,
        docMark_open: false,
        hasDelayPremission: false, // 活动下状态
        webinar_type: 0, // 活动下状态
        speed: 1,
        speedText: '倍速',
        speedList: [
          {
            label: '0.5x',
            value: 0.5
          },
          {
            label: '1.0x',
            value: 1
          },
          {
            label: '1.25x',
            value: 1.25
          },
          {
            label: '1.5x',
            value: 1.5
          },
          {
            label: '2.0x',
            value: 2
          }
        ],
        pageThemeColors: ['FFFFFF', 'BFBFBF', '1A1A1A', 'FB3A32', 'FFB201', '16C973', '3562FA'],
        formHorse: {
          color: '#BFBFBF', // 六位
          text_type: 2,
          size: 12,
          speed: 6000,
          text: '版权所有，盗版必究',
          position: 1,
          alpha: 100,
          scroll_type: 1,
          interval: 20
        },
        fontList: [],
        formWatermark: {
          img_position: 2,
          img_url: '',
          img_alpha: 100,
          img_size: 2
        },
        domain_url: '',
        formOther: {
          progress: true,
          bulletChat: false,
          doubleSpeed: false,
          autoplay: false
        },
        videoParam: {
          paas_record_id:
            process.env.VUE_APP_NODE_ENV === 'production' || process.env.VUE_APP_NODE_ENV === 'pre'
              ? paas_record_id_propagandaVideo['production']
              : paas_record_id_propagandaVideo['test']
        },
        marqueeOption: {
          enable: Boolean(this.scrolling_open),
          text: '版权所有，盗版必究',
          alpha: 100, // 透明度  100 完全显示   0 隐藏
          size: 20, // 文字大小
          color: '#FFFFFF', //  文字颜色
          interval: 20, // 下次跑马灯开始与本次结束的时间间隔 ， 秒为单位
          speed: 6000, // 跑马灯移动速度  3000快     6000中   10000慢
          displayType: 0,
          position: 1
        },
        docMarkOption: {
          enable: Boolean(this.docMark_open),
          doc_watermark_type: {
            text: 0, //固定文本
            user_id: 1, //观看者ID
            nick_name: 0, //观看者昵称
            text_value: '版权所有，盗版必究' //固定文本内容
          },
          alpha: 50, // 透明度  100 完全显示   0 隐藏
          size: 12, // 文字大小
          color: '#5a5a5a' //  文字颜色
        },
        rules: {
          interval: [{ required: true, validator: intervalValidate, trigger: 'blur' }]
        },
        vm: null,
        checkEnter: true, // 检验是否是第一次进来的
        audioImg: require('@/common/images/logo4.png'),
        appId: '',
        appToken: '',
        imgSize: [
          {
            label: '大',
            value: 1
          },
          {
            label: '中',
            value: 2
          },
          {
            label: '小',
            value: 3
          }
        ],
        imgSizeChose: 'medium',

        // 是否有下载文档水印设置的权限(admin配置)
        hasDownloadPdf: false,
        parentId: -1, // 父账户id, >0则表示当前是子账号
        childPermission: {} //子账号权限,只限制子账号
      };
    },
    components: {
      PageTitle,
      upload,
      ColorSet,
      beginPlay,
      PdfWatermark
    },
    watch: {
      isInWebinar(val) {
        if (!val) {
          this.playerOpen = true;
        }
      }
    },
    computed: {
      // true：活动下，false：账号下
      isInWebinar() {
        return this.$route.path.indexOf('/live/safeScreenSet') > -1;
      },
      horseLampText() {
        if (this.scrolling_open) {
          return '已开启，文字以跑马灯的形式出现在播放器画面中';
        } else {
          return '开启后，文字以跑马灯的形式出现在播放器画面中';
        }
      },
      waterMarkText() {
        if (this.watermark_open) {
          return '已开启，可在播放器中增加图片、水印';
        } else {
          return '开启后，可在播放器中增加图片、水印';
        }
      },
      docMarkText() {
        if (this.docMark_open) {
          return '已开启，可在文档上增加文字水印';
        } else {
          return '开启后，可在文档上增加文字水印';
        }
      },
      reservationDesc() {
        if (this.playerOpen) {
          return '已开启，使用当前活动的防录屏设置';
        } else {
          return '开启后，使用当前活动的防录屏设置';
        }
      },
      reservationDisable() {
        if (this.perssionWebInfo['player_config'] > 0) {
          return false;
        } else {
          return true;
        }
      },
      dialogVisible() {
        if (this.webinar_type == 6 && this.showGroupLive) {
          return true;
        } else {
          return false;
        }
      },
      showThirdTab() {
        if (
          this.hasDownloadPdf &&
          (this.parentId == 0 ||
            (this.parentId > 0 && this.childPermission?.permission_operating_watermark))
        ) {
          return true;
        }
        return false;
      }
    },
    async mounted() {
      this.userId = JSON.parse(sessionOrLocal.get('userId'));
      this._reportData = {
        business_uid: this.userId,
        user_id: '',
        webinar_id: '',
        refer: '',
        s: '',
        report_extra: {},
        ref_url: '',
        req_url: ''
      };
      if (this.isInWebinar) {
        this._reportData.webinar_id = this.$route.params.str;
      }
      this.getFontList();

      // 初始化admin配置的用户权限（非活动权限）
      this.initAdminUserPermission();

      if (this.isInWebinar) {
        await this.getLiveBaseInfo();
        await this.getPermission();
      }
      await this.getBasescrollingList();
      await this.getBaseWaterList();
      // 获取其他信息
      await this.getBaseOtherList();
      await this.getVideoAppid();
      this.initPlayer();
    },
    beforeDestroy() {
      if (this.$Vhallplayer) {
        this.$Vhallplayer.destroy();
      }
    },
    methods: {
      changeImgSize(value) {
        switch (value) {
          case 1:
            this.imgSizeChose = 'big';
            break;
          case 2:
            this.imgSizeChose = 'medium';
            break;
          case 3:
            this.imgSizeChose = 'small';
            break;
          default:
            this.imgSizeChose = 'medium';
        }
      },
      closeDialog() {
        if (this.webinar_type == 6) {
          this.showGroupLive = false;
        } else {
          this.showDelay = false;
        }
      },
      getLiveBaseInfo() {
        // webinar/info调整-与活动状态无关的调用
        let dataRes = this.$fetch('getWebinarInfo', { webinar_id: this.$route.params.str })
          .then(res => {
            if (res.code != 200) {
              return this.$vhMessage.warning(res.msg);
            }
            this.webinar_type = res.data.webinar_type;
            this.showDelay = res.data.no_delay_webinar == 1 ? true : false;
            this.showGroupLive = res.data.webinar_type == 6 ? true : false;
          })
          .catch(res => {
            this.$vhMessage({
              message: res.msg || '获取信息失败',
              showClose: true,
              type: 'error',
              customClass: 'zdy-info-box'
            });
            console.log(res);
          });
        return dataRes;
      },

      // 获取配置项
      getPermission() {
        let dataRes = this.$fetch('planFunctionGet', {
          webinar_id: this.$route.params.str,
          webinar_user_id: this.userId,
          scene_id: 1
        })
          .then(res => {
            if (res.code == 200) {
              let permissions = JSON.parse(res.data.permissions);
              this.playerOpen = permissions['is_player_cofig'] > 0 ? true : false;
              this.hasDelayPremission = permissions['no.delay.webinar'] == 1;
              console.log('permissions:', permissions);
              this.hasDownloadPdf = !!permissions['download_pdf'];
            }
          })
          .catch(e => {});
        return dataRes;
      },
      blurChange() {
        if (!this.formHorse.interval || this.formHorse.interval < 0) {
          this.formHorse.interval = 20;
        }
        this.editHorseInfo();
      },
      closePlayerOpen() {
        if (this.reservationDisable && !this.playerOpen) {
          this.playerOpen = true;
          this.$vhAlert(
            '尊敬的用户，您的账号无此权限。如需使用，请联系您的客户经理或专属售后，也可拨打400-888-9970转2咨询',
            '提示',
            {
              confirmButtonText: '我知道了',
              customClass: 'zdy-message-box',
              lockScroll: false,
              callback: action => {}
            }
          );
          return;
        }
        let params = {
          webinar_id: this.$route.params.str,
          permission_key: 'is_player_cofig',
          status: Number(this.playerOpen)
        };
        console.log('当前参数传递：', params);
        this.$fetch('planFunctionEdit', params)
          .then(res => {
            this.$vhall_paas_port({
              k: this.playerOpen ? 100227 : 100228,
              data: this._reportData
            });
            this.getBasescrollingList();
            this.getBaseWaterList();
            this.getBaseOtherList();
            if (!this.playerOpen) {
              this.$vhMessage({
                message: '正在使用账号下防录屏设置',
                showClose: true,
                type: 'warning',
                customClass: 'zdy-info-box'
              });
            }
          })
          .catch(res => {
            this.$vhMessage({
              message: res.msg || `操作失败`,
              showClose: true,
              type: 'error',
              customClass: 'zdy-info-box'
            });
          });
      },
      toSettingDetail() {
        if (this.reservationDisable) {
          this.$vhAlert(
            '尊敬的用户，您的账号无此权限。如需使用，请联系您的客户经理或专属售后，也可拨打400-888-9970转2咨询',
            '提示',
            {
              confirmButtonText: '我知道了',
              customClass: 'zdy-message-box',
              lockScroll: false,
              callback: action => {}
            }
          );
          return;
        }
        this.$vhall_paas_port({
          k: 100229,
          data: this._reportData
        });
        let { href } = this.$router.resolve({ path: '/setting/safeScreen' });
        window.open(href, '_blank');
      },
      // 获取appId
      getVideoAppid() {
        let dataRes = this.$fetch('getAppid').then(res => {
          this.appId = res.data.app_id;
          this.appToken = res.data.access_token;
        });
        return dataRes;
      },
      // 页面样式色值
      pageStyleHandle(color) {
        this.formHorse.color = color;
        this.editHorseInfo();
      },
      // 页面样式色值DOC
      pageStyleHandleDoc(color) {
        this.docMarkOption.color = color;
        this.editDocWaterInfo();
      },
      getFontList() {
        let num = 10;
        while (num <= 36) {
          this.fontList.push({ value: num });
          num = num + 2;
        }
      },
      // 关闭文档水印
      closeWaterDocInfo() {
        if (!this.docMark_open) {
          this.$vhall_paas_port({
            k: 100851,
            data: this._reportData
          });
          this.preWatermark(2);
        }
      },
      // 关闭跑马灯
      closeHorseInfo() {
        if (!this.scrolling_open) {
          this.$vhall_paas_port({
            k: 100231,
            data: this._reportData
          });
          this.preFormHorse();
        }
        this.editHorseInfo();
      },
      // 编辑跑马灯
      editHorseInfo() {
        this.getMarqueeOptionInfo();
        this.$Vhallplayer.editMarquee(this.marqueeOption);
      },
      // 关闭水印
      openWaterMarkInfo() {
        if (!this.watermark_open) {
          this.$vhall_paas_port({
            k: 100260,
            data: this._reportData
          });
          this.preWatermark(0);
        }
      },
      // 文档水印编辑，实时更新水印
      editDocWaterInfo() {
        this.getDocOptionInfo();
      },
      getDocOptionInfo() {
        let userInfo = JSON.parse(sessionOrLocal.get('userInfo'));
        if (!this.docMarkOption.doc_watermark_type.text_value) {
          this.docMarkOption.doc_watermark_type.text_value = '版权所有，盗版必究';
        }

        let waterText = '';
        let waterText_arr = [];
        this.docMarkOption.doc_watermark_type.text &&
          waterText_arr.push(this.docMarkOption.doc_watermark_type.text_value);
        this.docMarkOption.doc_watermark_type.user_id &&
          userInfo?.user_id &&
          waterText_arr.push(userInfo.user_id);
        this.docMarkOption.doc_watermark_type.nick_name &&
          userInfo?.nick_name &&
          waterText_arr.push(userInfo.nick_name);
        waterText = waterText_arr.join('-');

        this.docMarkOption = Object.assign({}, this.docMarkOption, {
          docMarkTxt: waterText
        });
      },
      getMarqueeOptionInfo() {
        let userInfo = JSON.parse(sessionOrLocal.get('userInfo'));
        if (!this.formHorse.text) {
          this.formHorse.text = '版权所有，盗版必究';
        }
        this.marqueeOption = {
          enable: Boolean(this.scrolling_open), // 默认 false
          text:
            this.formHorse.text_type == 2
              ? `${userInfo.user_id}${userInfo.nick_name}`
              : this.formHorse.text, // 跑马灯的文字
          alpha: this.formHorse.alpha, // 透明度  100 完全显示   0 隐藏
          size: this.formHorse.size, // 文字大小
          color: this.formHorse.color || '#FFFFFF', //  文字颜色
          interval: this.formHorse.scroll_type == 1 ? this.formHorse.interval : 1, // 下次跑马灯开始与本次结束的时间间隔 ， 秒为单位
          speed: this.formHorse.speed || 6000, // 跑马灯移动速度  3000快     6000中   10000慢
          displayType: this.formHorse.scroll_type == 1 ? 0 : 1,
          position: this.formHorse.position
        };
      },
      // 获取跑马灯基本信息
      getBasescrollingList() {
        let params = {
          type: this.playerOpen && this.isInWebinar ? 1 : 2,
          webinar_id: this.playerOpen && this.isInWebinar ? this.$route.params.str : ''
        };
        let dataRes = this.$fetch('getScrolling', this.$params(params)).then(res => {
          if (res.code == 200) {
            this.formHorse = { ...res.data };
            this.scrolling_open = Boolean(res.data.scrolling_open);
            this.getMarqueeOptionInfo();
          }
          this.$nextTick(() => {
            this.$refs.pageThemeColors.initColor(this.formHorse.color);
          });
        });
        return dataRes;
      },
      // 获取水印基本信息
      getBaseWaterList() {
        let params = {
          type: this.playerOpen && this.isInWebinar ? 1 : 2,
          webinar_id: this.playerOpen && this.isInWebinar ? this.$route.params.str : ''
        };
        let dataRes = this.$fetch('getWatermark', this.$params(params)).then(res => {
          if (res.code == 200) {
            this.formWatermark = { ...res.data };
            this.formWatermark.img_alpha = Number(res.data.img_alpha);
            this.domain_url = res.data.img_url;
            this.watermark_open = Boolean(res.data.watermark_open);
            this.docMark_open = Boolean(res.data.doc_watermark_open);
            this.docMarkOption = {
              enable: Boolean(this.docMark_open),
              doc_watermark_type: res.data.doc_watermark_type,
              alpha: res.data.doc_transparency === 0 ? 0 : res.data.doc_transparency || 100, // 透明度  100 完全显示   0 隐藏
              size: res.data.doc_font_size || 12, // 文字大小
              color: res.data.doc_font_color || '#5a5a5a'
            };
            this.changeImgSize(res.data.img_size || 2);
            this.getDocOptionInfo();
            this.$nextTick(() => {
              this.$refs.pageThemeColorsDoc.initColor(this.docMarkOption.color);
            });
          }
        });
        return dataRes;
      },
      // 获取其他基本信息
      getBaseOtherList() {
        let params = {
          type: this.playerOpen && this.isInWebinar ? 1 : 2,
          webinar_id: this.playerOpen && this.isInWebinar ? this.$route.params.str : ''
        };
        let dataRes = this.$fetch('getOtherOptions', this.$params(params))
          .then(res => {
            if (res.code == 200) {
              this.formOther.bulletChat = Boolean(res.data.barrage_button);
              this.formOther.progress = Boolean(res.data.progress_bar);
              this.formOther.doubleSpeed = Boolean(res.data.speed);
              this.formOther.autoplay = Boolean(res.data.autoplay);
            }
          })
          .catch(res => {
            this.$vhMessage({
              message: res.msg || `获取信息失败`,
              showClose: true,
              // duration: 0,
              type: 'error',
              customClass: 'zdy-info-box'
            });
          });
        return dataRes;
      },
      // 保存跑马灯
      preFormHorse() {
        // 校验间隔时间的输入
        if (this.formHorse.interval > 300) {
          this.$vhMessage({
            message: `间隔时间只能输入1-300之间的数字`,
            showClose: true,
            type: 'error',
            customClass: 'zdy-info-box'
          });
          return false;
        }
        this.formHorse.webinar_id = this.isInWebinar ? this.$route.params.str : '';
        this.formHorse.interval = this.formHorse.interval || 10;
        this.formHorse.text = this.formHorse.text || '版权所有，盗版必究';
        this.formHorse.scrolling_open = Number(this.scrolling_open);
        this.formHorse.type = this.isInWebinar ? 1 : 2;
        let params = JSON.parse(JSON.stringify(this.formHorse));
        // if(params.text_type == 2){
        //   delete params.text
        // }
        this.$fetch('setScrolling', this.$params(params))
          .then(res => {
            this.setHorseReportData();
            this.$vhMessage({
              message: this.scrolling_open ? '跑马灯开启成功' : '跑马灯关闭成功',
              showClose: true,
              type: 'success',
              customClass: 'zdy-info-box'
            });
          })
          .catch(res => {
            this.$vhMessage({
              message: res.msg || '保存跑马灯失败',
              showClose: true,
              type: 'error',
              customClass: 'zdy-info-box'
            });
          });
      },
      // 设置账号下跑马埋点数据
      setUserHorseReportData() {
        let loactionArr = [100653, 100654, 100655, 100656];
        let fontArr = [
          100657, 100658, 100659, 100660, 100661, 100662, 100663, 100664, 100665, 100666, 100667,
          100668, 100669, 100670
        ];
        if (this.scrolling_open) {
          this.$vhall_paas_port({
            k: 100644,
            data: this._reportData
          });
        }
        this.$vhall_paas_port({
          k: this.formHorse.scroll_type == 1 ? 100647 : 100646,
          data: this._reportData
        });
        this.$vhall_paas_port({
          k: this.formHorse.text_type == 1 ? 100648 : 100649,
          data: this._reportData
        });
        this.$vhall_paas_port({
          k: this.formHorse.speed == 3000 ? 100652 : this.formHorse.speed == 6000 ? 100651 : 100650,
          data: this._reportData
        });
        this.$vhall_paas_port({
          k: loactionArr[this.formHorse.position - 1],
          data: this._reportData
        });
        this.$vhall_paas_port({
          k: fontArr[(this.formHorse.size - 10) / 2],
          data: this._reportData
        });
        this.$vhall_paas_port({
          k: 100671,
          data: this._reportData
        });
        this.$vhall_paas_port({
          k: 100672,
          data: {
            ...this._reportData,
            report_extra: { interval: this.formHorse.interval }
          }
        });
      },
      // 设置跑马埋点数据
      setHorseReportData() {
        if (this.isInWebinar) {
          this.setWebinarHorseReportData();
        } else {
          this.setUserHorseReportData;
        }
      },
      // 设置活动下跑马埋点数据
      setWebinarHorseReportData() {
        console.log(this.formHorse);
        let loactionArr = [100239, 100240, 100241, 100242];
        let fontArr = [
          100243, 100244, 100245, 100246, 100247, 100248, 100249, 100250, 100251, 100252, 100253,
          100254, 100255, 100256
        ];
        if (this.scrolling_open) {
          this.$vhall_paas_port({
            k: 100230,
            data: this._reportData
          });
        }
        this.$vhall_paas_port({
          k: this.formHorse.scroll_type == 1 ? 100233 : 100232,
          data: this._reportData
        });
        this.$vhall_paas_port({
          k: this.formHorse.text_type == 1 ? 100234 : 100235,
          data: this._reportData
        });
        this.$vhall_paas_port({
          k: this.formHorse.speed == 3000 ? 100238 : this.formHorse.speed == 6000 ? 100237 : 100236,
          data: this._reportData
        });
        this.$vhall_paas_port({
          k: loactionArr[this.formHorse.position - 1],
          data: this._reportData
        });
        this.$vhall_paas_port({
          k: fontArr[(this.formHorse.size - 10) / 2],
          data: this._reportData
        });
        this.$vhall_paas_port({
          k: 100257,
          data: this._reportData
        });
        this.$vhall_paas_port({
          k: 100258,
          data: {
            ...this._reportData,
            report_extra: { interval: this.formHorse.interval }
          }
        });
      },
      // 保存水印
      preWatermark(index) {
        if (!this.domain_url && this.watermark_open && index == 1) {
          this.$vhMessage({
            message: `水印图片不能为空`,
            showClose: true,
            // duration: 0,
            type: 'error',
            customClass: 'zdy-info-box'
          });
          return;
        }
        if (
          this.docMark_open &&
          this.docMarkOption.doc_watermark_type.text +
            this.docMarkOption.doc_watermark_type.user_id +
            this.docMarkOption.doc_watermark_type.nick_name ===
            0 &&
          index == 1
        ) {
          this.$vhMessage({
            message: `文本类型不能为空`,
            showClose: true,
            // duration: 0,
            type: 'error',
            customClass: 'zdy-info-box'
          });
          return;
        }
        let params = {};
        if (index === 0) {
          if (!this.watermark_open) {
            this.formWatermark.img_url = this.$parseURL(this.domain_url).path;
            this.formWatermark.watermark_open = Number(this.watermark_open);
            this.formWatermark.type = this.isInWebinar ? 1 : 2;
            params = Object.assign(
              {},
              {
                webinar_id: this.isInWebinar ? this.$route.params.str : '',
                watermark_open: this.formWatermark.watermark_open,
                type: this.isInWebinar ? 1 : 2,
                img_url: this.formWatermark.img_url,
                img_alpha: this.formWatermark.img_alpha,
                img_position: this.formWatermark.img_position
              }
            );
          }
        } else if (index === 2) {
          if (!this.docMark_open) {
            params = Object.assign(
              {},
              {
                webinar_id: this.isInWebinar ? this.$route.params.str : '',
                type: this.isInWebinar ? 1 : 2,
                doc_watermark_open: Number(this.docMark_open),
                doc_watermark_type: JSON.stringify(this.docMarkOption.doc_watermark_type),
                doc_font_size: this.docMarkOption.size,
                doc_font_color: this.docMarkOption.color,
                doc_transparency: this.docMarkOption.alpha
              }
            );
          }
        } else {
          this.formWatermark.img_url = this.$parseURL(this.domain_url).path;
          this.formWatermark.watermark_open = Number(this.watermark_open);
          this.formWatermark.type = this.isInWebinar ? 1 : 2;
          params = Object.assign({}, this.formWatermark, {
            doc_watermark_open: Number(this.docMark_open),
            doc_watermark_type: JSON.stringify(this.docMarkOption.doc_watermark_type),
            doc_font_size: this.docMarkOption.size,
            doc_font_color: this.docMarkOption.color,
            doc_transparency: this.docMarkOption.alpha,
            webinar_id: this.isInWebinar ? this.$route.params.str : ''
          });
        }
        this.$fetch('setWatermark', this.$params(params))
          .then(res => {
            if (index === 0) {
              //播放器水印
              this.$vhMessage({
                message: this.watermark_open ? '保存成功' : '播放器水印关闭成功',
                showClose: true,
                // duration: 0,
                type: 'success',
                customClass: 'zdy-info-box'
              });
            } else if (index === 2) {
              //文档水印
              this.$vhMessage({
                message: this.docMark_open ? '保存成功' : '文档水印关闭成功',
                showClose: true,
                // duration: 0,
                type: 'success',
                customClass: 'zdy-info-box'
              });
            } else if (index === 1) {
              //播放器水印
              this.$vhMessage({
                message: '保存成功',
                showClose: true,
                // duration: 0,
                type: 'success',
                customClass: 'zdy-info-box'
              });
              this.setWaterReportData();
            }
          })
          .catch(res => {
            this.$vhMessage({
              message: res.msg || '保存水印失败',
              showClose: true,
              // duration: 0,
              type: 'error',
              customClass: 'zdy-info-box'
            });
          });
      },
      setUserWaterReportData() {
        let loactionArr = [100675, 100676, 100678, 100677];
        if (this.watermark_open) {
          this.$vhall_paas_port({
            k: 100673,
            data: this._reportData
          });
        }
        if (this.docMark_open) {
          this.$vhall_paas_port({
            k: 100848,
            data: this._reportData
          });
        }
        this.$vhall_paas_port({
          k: loactionArr[this.formWatermark.img_position - 1],
          data: this._reportData
        });
        this.$vhall_paas_port({
          k: 100679,
          data: {
            ...this._reportData,
            report_extra: { alpha: this.formWatermark.img_alpha }
          }
        });
      },
      setWaterReportData() {
        if (this.isInWebinar) {
          this.setWebinarWaterReportData();
        } else {
          this.setUserWaterReportData();
        }
      },
      setWebinarWaterReportData() {
        let loactionArr = [100261, 100262, 100264, 100263];
        if (this.watermark_open) {
          this.$vhall_paas_port({
            k: 100259,
            data: this._reportData
          });
        }
        if (this.docMark_open) {
          this.$vhall_paas_port({
            k: 100849,
            data: this._reportData
          });
        }
        this.$vhall_paas_port({
          k: loactionArr[this.formWatermark.img_position - 1],
          data: this._reportData
        });
        this.$vhall_paas_port({
          k: 100265,
          data: {
            ...this._reportData,
            report_extra: { alpha: this.formWatermark.img_alpha }
          }
        });
      },
      //文案提示问题
      messageInfo() {
        this.vm = this.$vhMessage({
          showClose: true,
          duration: 2000,
          message: '设置成功',
          type: 'success',
          customClass: 'zdy-info-box'
        });
      },
      // 初始化播放器
      initPlayer() {
        this.initSDK().then(() => {
          this.$Vhallplayer && this.$Vhallplayer.play();
          this.listen();
        });
      },
      // 删除图片
      deleteImg() {
        this.formWatermark.img_url = '';
        this.domain_url = '';
      },
      fromalAlign(val) {
        let text;
        switch (parseInt(val)) {
          case 1:
            text = 'tl';
            break;
          case 2:
            text = 'tr';
            break;
          case 4:
            text = 'bl';
            break;
          case 3:
            text = 'br';
            break;
        }
        return text;
      },
      initSDK() {
        const incomingData = {
          appId: this.appId, // 应用ID，必填
          accountId: this.userId || 1, // 第三方用户ID，必填
          token: this.appToken, // access_token，必填
          type: 'vod', // live 直播  vod 点播  必填
          videoNode: 'videoDom', // 播放器的容器， div的id 必填
          poster: '', // 封面地址  仅支持.jpg
          autoplay: true,
          vodOption: {
            recordId: this.videoParam.paas_record_id,
            forceMSE: false
          },
          marqueeOption: this.marqueeOption,
          watermarkOption: {
            // 选填
            enable: false, // 默认 false
            url: this.domain_url || this.audioImg, // 水印图片的路径
            align: this.fromalAlign(this.formWatermark.img_position), // 图片的对其方式， tl | tr | bl | br 分别对应：左上，右上，左下，右下
            position: ['20px', '20px'], // 对应的横纵位置，支持px,vh,vw,%
            size: ['60px', '20px'], // 水印大小，支持px,vh,vw,%  默认 80 35
            alpha: this.formWatermark.img_alpha
          },
          subtitleOption: {
            enable: true
          }
        };
        return new Promise(resolve => {
          console.log('======实例化播放器参数======', incomingData);
          window.VhallPlayer.createInstance(
            incomingData,
            event => {
              console.log('初始化实例成功', event);
              this.$Vhallplayer = event.vhallplayer;
              window.vp = this.$Vhallplayer;
              this.$Vhallplayer.openControls(false);
              this.$Vhallplayer.setVolume(0);
              if (this.formOther.doubleSpeed) {
                this.$Vhallplayer.setPlaySpeed(this.speed);
              }
              this.$Vhallplayer.on(window.VhallPlayer.LOADED, () => {
                // 加载中
                resolve();
              });
            },
            e => {
              console.log('播放器创建实例失败', e, e.message);
              throw new Error(e.message);
            }
          );
        });
      },
      uploadAdvSuccess(res, file) {
        console.log(res, file);
        if (res.data) {
          let domain_url = res.data.domain_url || '';
          let file_url = res.data.file_url || '';
          this.formWatermark.img_url = file_url;
          this.domain_url = domain_url;
        }
      },
      beforeUploadHnadler(file) {
        console.log(file);
        const typeList = ['png', 'jpeg', 'gif', 'bmp'];
        console.log(file.type.toLowerCase());
        let typeArr = file.type.toLowerCase().split('/');
        const isType = typeList.includes(typeArr[typeArr.length - 1]);
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isType) {
          this.$vhMessage({
            message: `水印图片只能是 ${typeList.join('、')} 格式`,
            showClose: true,
            // duration: 0,
            type: 'error',
            customClass: 'zdy-info-box'
          });
          return false;
        }
        if (!isLt2M) {
          this.$vhMessage({
            message: `水印图片大小不能超过 2M`,
            showClose: true,
            // duration: 0,
            type: 'error',
            customClass: 'zdy-info-box'
          });
          return false;
        }
        return isType && isLt2M;
      },
      uploadProcess(event, file, fileList) {
        console.log('uploadProcess', event, file, fileList);
      },
      uploadError(err, file, fileList) {
        console.log('uploadError', err, file, fileList);
        this.$vhMessage({
          message: `水印图片上传失败`,
          showClose: true,
          // duration: 0,
          type: 'error',
          customClass: 'zdy-info-box'
        });
      },
      uploadPreview(file) {
        console.log('uploadPreview', file);
      },
      handleFileChange(file) {
        console.log(file);
      },
      handleClick(tab) {
        this.activeName = tab.name;
        if (this.activeName === 'third') {
          // 暂停
          this.$Vhallplayer && this.$Vhallplayer.pause();
        }
      },
      // 跳转进入直播间设置逻辑
      toLivingSetPage() {
        jump({
          path: `${this.isEmbed ? '/embed' : ''}/live/livingSet/${this.$route.params.str}`,
          query: { type: this.$route.query.type, isDelay: this.$route.query.isDelay }
        });
      },

      // 初始化用户权限
      initAdminUserPermission() {
        const SAAS_VS_PES = sessionOrLocal.get('SAAS_VS_PES', 'localStorage');
        let permissions = SAAS_VS_PES ? JSON.parse(SAAS_VS_PES) : null;
        this.hasDownloadPdf = permissions ? !!permissions['download_pdf'] : false;

        let userInfo = sessionOrLocal.get('userInfo');
        if (userInfo) {
          userInfo = JSON.parse(userInfo);
          this.parentId = userInfo.parent_id;
          if (this.parentId > 0) {
            // 有子账号
            this.initChildPermission();
          }
        }
      },
      initChildPermission() {
        this.$fetch('getChildPermission')
          .then(res => {
            console.log('getChildPermission', res);
            sessionOrLocal.set('SAAS_V3_SON_PS', JSON.stringify(res.data));
            this.childPermission = res.data;
          })
          .catch(err => {
            console.error(err);
            sessionOrLocal.removeItem('SAAS_V3_SON_PS');
            this.childPermission = {};
          });
      }
    }
  };
</script>

<style lang="less" scoped>
  #videoDom {
    width: 100%;
    height: 100%;
  }
  .prize-card {
    height: 100%;
    .title_text {
      .tip-text {
        font-size: 14px;
        margin-left: 20px;
        cursor: pointer;
        color: #3562fa;
        vertical-align: middle;
      }
    }

    .player-set {
      background: #fff;
      position: relative;
      border-radius: 4px;
      ::v-deep .vh-tabs__content {
        width: 50%;
        padding: 0;
      }
    }
    .player-set.third {
      ::v-deep .vh-tabs__content {
        width: 100%;
      }
    }

    .textType {
      width: 360px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .common-save {
      width: 160px;
      height: 40px;
      line-height: 15px;
    }
    .isNum {
      position: absolute;
      top: -2px;
      right: 0px;
      color: #fb3a32;
    }
    .give-item {
      padding: 40px 24px;
      display: flex;
      position: relative;
      ::v-deep .vh-form {
        position: relative;
        i {
          font-style: normal;
        }
      }
      .give-prize {
        width: 460px;
      }
      .give-show {
        width: 600px;
        height: 500px;
        flex: 1;
        border: 1px solid #ccc;
      }
      .give-white {
        position: absolute;
        top: 80px;
        width: 100%;
        bottom: 0;
        background: rgba(255, 255, 255, 0.5);
        z-index: 9;
      }

      .webinarTop {
        top: 80px;
      }
      .userTop {
        top: 20px;
      }
    }

    .block {
      height: 50px;
    }
    .waterSet {
      .give-white {
        position: absolute;
        width: 100%;
        height: 315px;
        background: rgba(255, 255, 255, 0.5);
        z-index: 9;
        &.webinarTop {
          top: 100px;
        }
        &.userTop {
          height: 380px;
          top: 30px;
        }
      }
      .give-white-doc {
        position: absolute;
        width: 100%;
        height: 340px;
        background: rgba(255, 255, 255, 0.5);
        z-index: 9;
        &.webinarTop {
          top: 560px;
        }
        &.userTop {
          top: 440px;
          height: 520px;
        }
      }
    }

    .show-purple {
      width: 400px;
      border-radius: 5px;
      position: absolute;
      top: 95px;
      left: 54%;
      img {
        width: 400px;
        height: 226px;
      }
      .video-wrap {
        position: relative;
        width: 400px;
        overflow: hidden;
        background: #000000;
        height: 226px;
        ::v-deep .vhallPlayer-brarage-component {
          display: none;
        }
        .waterMark {
          position: absolute;
          &.big {
            width: 120px;
            height: 40px;
          }
          &.medium {
            width: 100px;
            height: 32px;
          }
          &.small {
            width: 80px;
            height: 24px;
          }
          width: 100px;
          height: 32px;
          &.position_1 {
            top: 10px;
            left: 8px;
          }
          &.position_2 {
            top: 10px;
            right: 8px;
          }
          &.position_3 {
            bottom: 40px;
            right: 8px;
          }
          &.position_4 {
            bottom: 40px;
            left: 8px;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: scale-down;
          }
        }

        .player_no_bulletChat {
          ::v-deep .vhallPlayer-brarage-component {
            display: none;
          }
        }

        .player_no_doubleSpeed {
          ::v-deep .vhallPlayer-speed-component {
            display: none;
          }
        }

        .player_no_progress {
          ::v-deep .vhallPlayer-progress-box {
            display: none;
          }
        }
      }

      &-info {
        width: 100%;
        margin-top: 15px;
        span {
          display: block;
          color: #999;
          line-height: 20px;
          font-size: 14px;
        }
      }
    }
    .video-wrap {
      position: relative;
      .waterMark {
        position: absolute;
        width: 80px;
        height: 30px;
        &.position_1 {
          top: 10px;
          left: 8px;
        }
        &.position_2 {
          top: 10px;
          right: 8px;
        }
        &.position_3 {
          bottom: 40px;
          right: 8px;
        }
        &.position_4 {
          bottom: 40px;
          left: 8px;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: scale-down;
        }
      }
      .active {
        bottom: 0px;
      }
    }
    .preview-video {
      position: absolute;
      top: -41px;
      left: 0;
    }
    .docMark {
      width: 400px;
      border-radius: 5px;
      position: absolute;
      top: 545px;
      left: 54%;

      > img {
        width: 400px;
        height: 16px;
        margin-bottom: -18px;
        z-index: 9;
        position: relative;
      }
      .preview {
        width: 400px;
        height: 264px;
        background: url('../../../common/images/waterMark.png') no-repeat center;
        background-size: contain;
        overflow: hidden;
        min-height: initial;
        .mark {
          width: 2100px;
          height: 1500px;
          transform-origin: center;
          transform: translate(-840px, -700px) rotate(-15deg) scale(0.4);
          div {
            text-align: center;
            display: inline-block;
            margin: 0 auto;
            display: flex;
            overflow: hidden;
            width: 100%;
            span {
              display: flex;
              align-items: flex-start;
              margin: 0 30px;
              min-width: fit-content;
              &:nth-child(odd) {
                align-items: flex-end;
              }
            }
          }
        }
      }
      .alert {
        width: 100%;
        margin-top: 15px;
        span {
          display: block;
          color: #999;
          line-height: 20px;
          font-size: 14px;
        }
        .safe__link {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          text-align: justify;
          color: #4e76fb;
          margin-left: 8px;
          margin-right: 8px;
        }
      }
    }

    .PdfWatermark {
      .give-white {
        position: absolute;
        top: 20px;
        width: 100%;
        bottom: 0;
        background: rgba(255, 255, 255, 0.5);
        z-index: 9;
      }
    }
  }

  .cut-line {
    width: calc(100% - 96px);
    height: 1px;
    position: absolute;
    top: 520px;
    left: 48px;
    background-color: #e6e6e6;
  }

  /deep/ .div__sketch {
    left: -170px;
  }
</style>

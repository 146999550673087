var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"watermark-preview",style:({
    width: `${_vm.width}px`,
    height: `${_vm.height}px`
  }),attrs:{"id":"watermarkPre"}},[_c('div',{staticClass:"watermark-preview__bg",style:({
      width: `${_vm.maxSide}px`,
      height: `${_vm.maxSide}px`,
      transform: `translate(-50%,-50%) rotate(${_vm.rotate}deg) scale(0.2)`,
      backgroundImage: `url(${_vm.bgImage})`
    })}),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
<!-- 通用水印预览 -->
<template>
  <div
    id="watermarkPre"
    class="watermark-preview"
    :style="{
      width: `${width}px`,
      height: `${height}px`
    }"
  >
    <div
      class="watermark-preview__bg"
      :style="{
        width: `${maxSide}px`,
        height: `${maxSide}px`,
        transform: `translate(-50%,-50%) rotate(${rotate}deg) scale(0.2)`,
        backgroundImage: `url(${bgImage})`
      }"
    ></div>
    <slot></slot>
  </div>
</template>
<script>
  export default {
    name: 'watermarkPreview',
    props: {
      width: {
        type: Number,
        default: '300'
      },
      height: {
        type: Number,
        default: '400'
      },
      text: {
        type: String,
        default: '版权所有，盗版必究'
      },
      color: {
        type: String,
        default: '#BFBFBF'
      },
      fontSize: {
        type: Number | String,
        default: 12
      },
      // 不透明度
      opacity: {
        type: Number,
        default: 10
      },
      // 0：单行， 1：多行
      showLine: {
        type: Number,
        validator: function (value) {
          return [0, 1].includes(value);
        },
        default: () => {
          return 0;
        }
      },
      // 倾斜角度
      rotate: {
        type: Number,
        default: -45
      }
    },
    data() {
      return {
        bgImage: undefined
      };
    },
    computed: {
      // 背景容器(正方形)边长
      maxSide() {
        return Math.ceil(Math.sqrt(Math.pow(this.width, 2) + Math.pow(this.height, 2))) * 5;
      },
      allProps() {
        // 以对象形式返回，以便可以作为 watch 的对象
        return {
          text: this.text,
          color: this.color,
          fontSize: this.fontSize,
          opacity: this.opacity,
          showLine: this.showLine
        };
      }
    },
    watch: {
      allProps: {
        handler(newVals, oldVals) {
          // 每当 props 变化时，生成新的背景水印
          this.createWatermark();
        },
        deep: true
      }
    },
    mounted() {
      this.$nextTick(() => {
        this.createWatermark();
      });
    },
    methods: {
      /**
       * 创建水印，确保预览区域居中部分能看到水印
       */
      createWatermark() {
        const domWatermarkPre = document.getElementById('watermarkPre');
        if (!domWatermarkPre) return;
        // const domWatermarkPreSty = window.getComputedStyle(domWatermarkPre);
        // 预览区域宽高
        // const cw = parseInt(domWatermarkPreSty.getPropertyValue('width'));
        // const ch = parseInt(domWatermarkPreSty.getPropertyValue('height'));
        const cw = this.width;

        // 创建一个 canvas 元素，并设置宽高
        // 水印容器用正方形，边长为原容器的斜边，要保证旋转后能完全覆盖
        let canvas = document.createElement('canvas');
        canvas.width = this.maxSide;
        canvas.height = this.maxSide;

        // 创建2d绘制api
        const ctx = canvas.getContext('2d');

        // 设置全局透明度
        ctx.globalAlpha = this.opacity / 100; // 0 完全透明，1

        // 获取默认字体
        const rootFontFamily = (
          document.documentElement.currentStyle
            ? document.documentElement.currentStyle
            : window.getComputedStyle(document.documentElement)
        ).fontFamily;
        // 文本基线
        ctx.textBaseline = 'middle'; // 垂直居中
        // 设置水印文字大小、字体、颜色
        ctx.font = `${this.fontSize}px ${rootFontFamily}`;
        ctx.fillStyle = this.color;
        // 文本内容
        const text = this.text;

        const metrics = ctx.measureText(text);
        // 文字宽度
        const textWidth = metrics.width;
        console.log('water textWidth:', typeof textWidth);
        // 文字高度
        // 兼容性处理，fontBoundingBoxAscent属性值可能没有， 最终兜底的文字高度设置为80
        const fontHeight = metrics.fontBoundingBoxAscent
          ? Math.ceil(metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent)
          : metrics.actualBoundingBoxAscent
          ? Math.ceil(metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent)
          : 80;
        // 绘制文字的水平偏移量
        const woffset = textWidth + Math.ceil(textWidth / text.length) * 2;

        // 绘制起始位置
        let startX = (this.maxSide - cw) / 2 + (cw > textWidth ? (cw - textWidth) / 2 : 0); //水平起始位置
        while (startX > 0) startX = startX - woffset;

        let startY = this.maxSide / 2; //垂直起始位置

        if (this.showLine == 0) {
          // 单行，只绘制垂直居中的水印就行
          for (let x = startX; x <= canvas.width; x = x + woffset) {
            ctx.fillText(text, x, startY);
          }
        } else {
          // 多行
          while (startY > 0) startY = startY - 3 * fontHeight;
          const hoffset = 3 * fontHeight; // 高度偏移量
          for (let x = startX; x <= canvas.width; x = x + woffset) {
            for (let y = startY; y <= canvas.height; y = y + hoffset) {
              ctx.fillText(text, x, y);
            }
          }
        }
        // 返回base64编码图片，质量1.0(默认是0.92)
        this.bgImage = canvas.toDataURL('image/png', 1.0);
        canvas = null;
      }
    }
  };
</script>
<style lang="less" scoped>
  .watermark-preview {
    position: relative;
    overflow: hidden;
    border: 1px solid #ccc;
    box-sizing: border-box;

    &__bg {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform-origin: center center;
      overflow: hidden;
    }
  }
</style>
